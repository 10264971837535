import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { first } from 'rxjs';

import { ActiveProjectState } from '../active-project/models/active-project-state.model';
import { ProductCatalogArticle } from '../api-client/services/lcas-api-client/models/catalog-product.model';
import { Version } from '../api-client/services/lcas-api-client/models/version.model';

@Injectable()
export class ThingVersionComparerService {
  private deviceAndFunctionProducts: ProductCatalogArticle[] | undefined =
    undefined;

  constructor(
    private store: Store<{
      project: ActiveProjectState;
    }>,
  ) {
    this.store
      .select('project')
      .pipe(first())
      .subscribe((project) => {
        this.deviceAndFunctionProducts = [
          ...project.latestDevicesCatalog,
          ...project.latestFunctionsCatalog,
        ];
      });
  }

  isVersionOutdated(uniqueName: string, version: Version): boolean | undefined {
    if (
      this.deviceAndFunctionProducts === undefined ||
      this.deviceAndFunctionProducts.length === 0
    ) {
      return undefined;
    }

    const productArticle: ProductCatalogArticle | undefined =
      this.deviceAndFunctionProducts.find(
        (article) => article.uniqueName === uniqueName,
      );

    if (productArticle === undefined) {
      return undefined;
    }

    if (version.model < productArticle.version.model) {
      return true;
    }

    return false;
  }
}
