import { Component, Input } from '@angular/core';
import { ModalRef } from '@simpl/element-ng';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { LocationsState } from '../../../../shared/location-store/models/location.model';
import { LocationActions } from '../../../../shared/location-store/store/location.actions';

@Component({
  selector: 'app-create-location-modal',
  template: `
    <app-create-floor-or-location-modal
      [title]="title"
      [defaultName]="name"
      [modalRef]="modalRef"
      (save)="save($event)"
    ></app-create-floor-or-location-modal>
  `,
})
export class CreateLocationModalComponent {
  @Input() modalRef?: ModalRef;

  @Input() floorId?: string;

  readonly title = 'BUILDING.ADD_ROOM';

  readonly name = this.translateService.instant('BUILDING.ADD_ROOM_NAME');

  constructor(
    private translateService: TranslateService,
    private store: Store<{
      floorsAndLocations: LocationsState;
    }>,
  ) {}

  save(name: string) {
    this.store.dispatch(
      LocationActions.initAddLocation({
        floorId: this.floorId!,
        location: {
          name,
          locationType: 'ROOM',
        },
      }),
    );
    this.modalRef?.hide();
  }
}
