import { CdkPortal } from '@angular/cdk/portal';
import {
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  SiSidePanelService,
  SiToastNotificationService,
} from '@simpl/element-ng';
import { isEqual } from 'lodash';
import {
  combineLatest,
  defaultIfEmpty,
  distinctUntilChanged,
  filter,
  first,
  map,
  Observable,
  of,
  share,
  startWith,
  Subscription,
  switchMap,
  tap,
} from 'rxjs';

import { LcasApiClientService } from '../../../../shared/api-client/services/lcas-api-client/lcas-api-client.service';
import { PlannedDevice } from '../../../../shared/api-client/services/lcas-api-client/models/planned-device.model';
import { EdgeDeviceState } from '../../../../shared/devices-commissioning/models/edge-device-state.model';
import { DevicePropertiesService } from '../../../../shared/devices-commissioning/services/device-properties.service';
import { EdgeConnectivityService } from '../../../../shared/devices-commissioning/services/edge-connectivity.service';
import { PlannedDevicesPollingService } from '../../../../shared/devices-commissioning/services/planned-devices-polling.service';
import {
  WIRELESS_ROOM_SENSORS,
  WirelessRoomSensorType,
} from '../../../../shared/models/business/wireless-room-sensor-type.enum';
import { EdgeConnectivityInfo } from '../../../../shared/models/edge-connectivity-info.model';
import { DeviceDetailsRow } from '../../../shared/generic-components/devices-details/models/device-details-row.model';
import { DeviceRow } from '../../../shared/generic-components/devices-details/models/device-row.model';
import { DevicesTableConfig } from '../../../shared/generic-components/devices-details/models/devices-table-config.model';
import { LocationsState } from '../../../shared/location-store/models/location.model';
import { LocationType } from '../../../shared/models/location-type.enum';
import { LocationNavigationService } from '../../../shared/services/location-navigation.service';
import { MainDetailContainerService } from '../../../shared/services/main-detail-container.service';
import { StoreBridgeService } from '../../../shared/services/store-bridge.service';
import { DeviceCommissioningService } from '../device-installation/services/device-commissioning.service';
import { DevicesTableDataService } from '../services/devices-table-data.service';

@Component({
  selector: 'app-planned-devices',
  templateUrl: './planned-devices.component.html',
  providers: [LocationNavigationService],
})
export class PlannedDevicesComponent implements OnInit, OnDestroy {
  @HostBinding('class') classes = 'd-flex flex-column flex-grow-1';

  @ViewChild('sidePanelContent', { read: CdkPortal })
  sidePanelContent?: CdkPortal;

  selectedLocationType: LocationType = LocationType.Floor;

  selectedFloorId?: string;

  selectedDetailRow?: DeviceDetailsRow;

  edgeConnectivityInfo: EdgeConnectivityInfo = {
    connected: false,
    fetchedConnectivity: false,
  };

  devicesTableConfig?: DevicesTableConfig;

  private newLocationToNavigateSubscription: Subscription = new Subscription();

  private edgeConnectivitySubscription: Subscription = new Subscription();

  private devicesTableConfigSubscription: Subscription = new Subscription();

  devicesTableData$?: Observable<DeviceRow[]> = combineLatest([
    this.activatedRoute.paramMap.pipe(
      map((paramMap) => ({
        selectedLocationId: paramMap.get('selectedLocationId') as string,
      })),
    ),
    this.store.select((store) => ({
      floorsAndLocations: store.floorsAndLocations,
    })),
  ]).pipe(
    distinctUntilChanged((prev, curr) => isEqual(prev, curr)),
    map(
      ([{ selectedLocationId }, { floorsAndLocations }]) =>
        floorsAndLocations.locationsWithDevices[
          selectedLocationId
        ] as PlannedDevice[],
    ),
    filter((devices) => devices !== undefined),
    switchMap((devices) =>
      combineLatest(
        devices.map((device) =>
          device.type === 'SysXController'
            ? of(device)
            : this.devicePropertiesService.calculateMainPropertyId(device),
        ),
      ).pipe(defaultIfEmpty(devices)),
    ),
    switchMap((devices) =>
      combineLatest([
        of(devices),
        this.store.select((state) => state.edgeDeviceState.edgeDevice),
      ]),
    ),
    switchMap(([devices, edge]) => {
      const currentRoomHasEdgeDevice = !!devices.find(
        (device) => device.type === 'SysXController',
      );
      this.edgeConnectivityService.stopPollingEdgeConnectivityStatus();

      if (currentRoomHasEdgeDevice) {
        return this.plannedDevicesPollingService
          .getDevicesWithRealTimeData(devices)
          .pipe(
            tap((devicesWithRealtimeData) => {
              const edgeDevice = devicesWithRealtimeData.find(
                (device) => device.type === 'SysXController',
              )!;

              if (edgeDevice.deviceStatus) {
                const isEdgeDeviceConnected =
                  edgeDevice.deviceStatus === 'connected';
                this.checkEdgeConnectivityInfo(isEdgeDeviceConnected);
              }
            }),
          );
      }

      if (edge) {
        this.edgeConnectivitySubscription.add(
          this.edgeConnectivityService
            .pollEdgeConnectivityStatus(edge)
            .subscribe((isEdgeDeviceConnected) => {
              this.checkEdgeConnectivityInfo(isEdgeDeviceConnected);
            }),
        );
      }

      return this.plannedDevicesPollingService.getDevicesWithRealTimeData(
        devices,
      );
    }),
    map((devicesWithRealTimeData) =>
      this.tableDataService.getTableData(devicesWithRealTimeData),
    ),
    startWith([]),
    share(),
  );

  constructor(
    private siSidePanelService: SiSidePanelService,
    private siToastNotificationService: SiToastNotificationService,
    private storeBridgeService: StoreBridgeService,
    private locationNavigationService: LocationNavigationService,
    private mainDetailContainerService: MainDetailContainerService,
    private deviceCommissioningService: DeviceCommissioningService,
    private lcasApiClientService: LcasApiClientService,
    private store: Store<{
      floorsAndLocations: LocationsState;
      edgeDeviceState: EdgeDeviceState;
    }>,
    private activatedRoute: ActivatedRoute,
    private devicePropertiesService: DevicePropertiesService,
    private edgeConnectivityService: EdgeConnectivityService,
    private plannedDevicesPollingService: PlannedDevicesPollingService,
    private tableDataService: DevicesTableDataService,
  ) {}

  ngOnInit(): void {
    this.initSubscriptions();
  }

  ngOnDestroy(): void {
    this.siSidePanelService.close();
    this.completeSubscriptions();
  }

  setSelectedLocationType(locationType: LocationType) {
    this.selectedLocationType = locationType;
    if (locationType === LocationType.Room) {
      this.mainDetailContainerService.notifyDetailsActive(true);
    }
  }

  closeDetailPanel() {
    this.siSidePanelService.close();
  }

  assignDevice(row: DeviceDetailsRow): void {
    if (this.isValidWirelessDevice(row)) {
      this.lcasApiClientService
        .getDevices({ type: ['BrdRout'] })
        .pipe(
          first(),
          tap((devices) => {
            if (
              devices.find(({ setupStatus }) => setupStatus === 'COMMISSIONED')
            ) {
              this.deviceCommissioningService.navigateToDeviceCommissioningWizard(
                row.id,
                this.activatedRoute,
              );
            } else {
              this.siToastNotificationService.queueToastNotification(
                'danger',
                'COMMISSIONING.TOAST.MESSAGE_NO_COMMISSIONED_BORDERROUTER',
                '',
              );
            }
          }),
        )
        .subscribe();
    } else {
      this.deviceCommissioningService.navigateToDeviceCommissioningWizard(
        row.id,
        this.activatedRoute,
      );
    }
  }

  openDetails(row: DeviceDetailsRow): void {
    this.selectedDetailRow = row;
    this.siSidePanelService.setSidePanelContent(this.sidePanelContent);
    this.siSidePanelService.open();
  }

  private checkEdgeConnectivityInfo(isEdgeDeviceConnected: boolean): void {
    if (
      this.edgeConnectivityService.shouldChangeEdgeConnectivityInfo(
        isEdgeDeviceConnected,
        this.edgeConnectivityInfo,
      )
    ) {
      this.edgeConnectivityInfo = {
        connected: isEdgeDeviceConnected,
        fetchedConnectivity: true,
      };
    }
  }

  private initSubscriptions(): void {
    this.newLocationToNavigateSubscription.add(
      this.storeBridgeService.newLocationToNavigate$.subscribe(
        ({ floorId, locationId }) => {
          this.locationNavigationService.initNavigation(
            floorId,
            locationId,
            true,
          );
        },
      ),
    );
    this.devicesTableConfigSubscription.add(
      this.activatedRoute.data.subscribe((data) => {
        this.devicesTableConfig = data.dataTableConfig;
      }),
    );
  }

  private isValidWirelessDevice(device: DeviceDetailsRow): boolean {
    return (
      ['RadVlvActr', 'Rout'].includes(device.type) ||
      WIRELESS_ROOM_SENSORS.includes(device.type as WirelessRoomSensorType)
    );
  }

  private completeSubscriptions(): void {
    this.newLocationToNavigateSubscription.unsubscribe();
    this.edgeConnectivitySubscription.unsubscribe();
    this.devicesTableConfigSubscription.unsubscribe();
  }
}
