<app-main-detail-container>
  <ng-template #masterActions let-viewData="viewData">
    <div slot="masterActions" class="btn-group dropdown">
      <button
        type="button"
        class="btn btn-secondary dropdown-toggle"
        #trigger="cdkOverlayOrigin"
        cdkOverlayOrigin
        [attr.data-testid]="'add-floor-room-dropdown'"
        [disabled]="viewData.isLoading"
        [ngSwitch]="viewData.treeItems.length"
      >
        <span
          *ngSwitchCase="0"
          [attr.data-testid]="'add-floor'"
          (click)="onAddLocationClick(locationTypeEnum.Floor)"
          >{{ 'BUILDING.ADD_FLOOR' | translate }}</span
        >
        <span
          *ngSwitchDefault
          [attr.data-testid]="'add-room'"
          (click)="onAddLocationClick(locationTypeEnum.Room)"
          >{{ 'BUILDING.ADD_ROOM' | translate }}</span
        >
        <i
          class="dropdown-caret icon element-down-2"
          [attr.data-testid]="'toggle-dropdown-icon'"
          (click)="dropdownActive = true"
        ></i>
      </button>
      <ng-template
        cdkConnectedOverlay
        cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
        [cdkConnectedOverlayOpen]="dropdownActive"
        [cdkConnectedOverlayOrigin]="trigger"
        [cdkConnectedOverlayHasBackdrop]="true"
        (backdropClick)="dropdownActive = false"
        (detach)="dropdownActive = false"
      >
        <div
          class="dropdown-menu position-static d-block"
          [cdkTrapFocus]="true"
          [cdkTrapFocusAutoCapture]="true"
        >
          <a
            class="dropdown-item"
            role="button"
            [attr.data-testid]="'add-floor'"
            (click)="
              onAddLocationClick(locationTypeEnum.Floor); dropdownActive = false
            "
          >
            <i class="dropdown-caret icon element-floor me-4"></i>
            <span>
              {{ 'BUILDING.ADD_FLOOR' | translate }}
            </span>
          </a>

          <a
            class="dropdown-item"
            role="button"
            [attr.data-testid]="'add-room'"
            [class.disabled]="viewData.treeItems.length === 0"
            (click)="
              onAddLocationClick(locationTypeEnum.Room); dropdownActive = false
            "
          >
            <i class="dropdown-caret icon element-room me-4"></i>
            <span>
              {{ 'BUILDING.ADD_ROOM' | translate }}
            </span>
          </a>
        </div>
      </ng-template>
    </div>
  </ng-template>

  <ng-template #masterData let-viewData="viewData">
    <app-locations-tree-view
      slot="masterData"
      *ngIf="viewData.treeItems.length; else noTreeItems"
      class="mb-9 w-100"
      [treeItems]="viewData.treeItems"
      [showActionButtons]="true"
      (openEdgeRelocationModal)="openEdgeRelocationModal($event)"
      (openDevicesRelocationModal)="openDevicesRelocationModal($event)"
      (locationType)="setSelectedLocationType($event)"
      (selectFloor)="selectedFloorId = $event"
      (editLocation)="openEditModal($event, viewData.floorsWithLocations)"
      (closeDetails)="closeDetailPanel()"
    >
    </app-locations-tree-view>
  </ng-template>

  <ng-template #detailActions let-viewData="viewData">
    <button
      slot="detailActions"
      type="button"
      class="btn btn-secondary dropdown-toggle"
      [attr.data-testid]="'add-devices-btn'"
      [disabled]="viewData.isLoading || !viewData.selectedLocation"
      (click)="onAddDevicesClick(viewData.selectedLocation)"
    >
      {{ 'BUILDING.ADD_DEVICES' | translate }}
    </button>
  </ng-template>

  <ng-template #devicesDetails let-viewData="viewData">
    <div slot="details" class="card elevation-1">
      <app-devices-details
        class="position-relative"
        [siBackdropWithSpinner]="viewData.isLoading"
        [selectedLocation]="viewData?.selectedLocation"
        [devicesTableConfig]="devicesTableConfig"
        [(selectedDetailRow)]="selectedDetailRow"
        (detailsClick)="openDetails($event)"
        (addDevicesClick)="onAddDevicesClick($event)"
      ></app-devices-details>
    </div>
  </ng-template>

  <ng-template #noTreeItems>
    <div
      slot="masterData"
      class="card elevation-1 pt-9 mb-9 text-center overflow-auto mh-100"
    >
      <app-empty-floor
        (addFloor)="onAddLocationClick(locationTypeEnum.Floor)"
      ></app-empty-floor>
    </div>
  </ng-template>
</app-main-detail-container>

<ng-template #addDevicesModal let-modalRef="modalRef">
  <app-add-devices-modal
    [location]="selectedLocation"
    [modalRef]="modalRef"
  ></app-add-devices-modal>
</ng-template>

<ng-template #createFloorModal let-modalRef="modalRef">
  <app-create-floor-modal [modalRef]="modalRef"></app-create-floor-modal>
</ng-template>

<ng-template #createRoomModal let-modalRef="modalRef">
  <app-create-location-modal
    [floorId]="selectedFloorId"
    [modalRef]="modalRef"
  ></app-create-location-modal>
</ng-template>

<ng-template #editFloorModal let-modalRef="modalRef">
  <app-edit-floor-modal
    [floor]="selectedFloor"
    [modalRef]="modalRef"
  ></app-edit-floor-modal>
</ng-template>

<ng-template #editLocationModal let-modalRef="modalRef">
  <app-edit-location-modal
    [location]="selectedLocation"
    [modalRef]="modalRef"
  ></app-edit-location-modal>
</ng-template>

<ng-template #sidePanelContent cdkPortal>
  <si-side-panel-content [heading]="selectedDetailRow?.name || ''">
    <app-details-card
      *ngIf="selectedDetailRow"
      [device]="selectedDetailRow"
    ></app-details-card>
  </si-side-panel-content>
</ng-template>

<ng-template #edgeRelocationModal let-modalRef="modalRef">
  <app-edge-device-modal
    #edgeRelocationModal
    [modalRef]="modalRef"
    [entityToDelete]="entityToDelete"
  >
  </app-edge-device-modal>
</ng-template>

<ng-template #devicesRelocationModal let-modalRef="modalRef">
  <app-devices-relocation-modal
    #devicesRelocationModal
    [modalRef]="modalRef"
    [entityToDelete]="entityToDelete"
    [linkToCommissioning]="linkToCommissioning"
  >
  </app-devices-relocation-modal>
</ng-template>
