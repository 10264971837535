<form *ngIf="formGroup" [formGroup]="formGroup">
  <ul class="ps-0 mb-0">
    <ng-container *ngFor="let property of properties">
      <li
        *ngIf="!property.data.readOnly"
        class="row d-flex list-group-item border-start-0 border-end-0 justify-content-between align-items-start"
      >
        <p
          class="col-6 si-body-1 text-secondary col"
          [attr.data-testid]="'prop-title-' + property.data.title"
        >
          {{ property.data.title }}
        </p>
        <div
          *ngIf="property.data['btzf:hasEnumMap']; else inputField"
          class="col-6 d-flex align-items-center"
        >
          <app-enum-select
            class="w-100"
            [options]="getOptions(property.data)"
            [formControlName]="property.id!"
            [edgeConnectivityInfo]="edgeConnectivityInfo"
            (optionsOpened)="stopPolling.emit()"
            (optionsClosed)="startPolling.emit()"
          ></app-enum-select>
        </div>
        <ng-template #inputField>
          <div
            class="col-sm-6 d-flex align-items-center justify-content-center"
          >
            <input
              [type]="getInputType(property)"
              [step]="property.data.multipleOf ?? 1"
              [ngClass]="getInputClass(property)"
              [readonly]="
                !!(
                  !edgeConnectivityInfo?.connected &&
                  edgeConnectivityInfo?.fetchedConnectivity
                )
              "
              [formControlName]="property.id!"
              [attr.data-testid]="'prop-input-' + property.data.title"
              (blur)="startPolling.emit()"
              (focus)="stopPolling.emit()"
            />
            <p
              class="col text-end ms-2 m-0"
              [class.text-muted]="
                !edgeConnectivityInfo?.connected &&
                edgeConnectivityInfo?.fetchedConnectivity
              "
              *ngIf="property.data.unit"
            >
              {{ property.data.unit | translate }}
            </p>
          </div>
        </ng-template>
        <p
          class="invalid-feedback col-6"
          *ngIf="
            getControl(property.id!)?.errors?.['min'] ||
            getControl(property.id!)?.errors?.['max']
          "
          [innerHtml]="
            property.data.maximum
              ? ('DEVICE_DETAIL_CARD.EXCEEDED_BOUNDARIES'
                | translate
                  : {
                      min: property.data.minimum ? property.data.minimum : 0,
                      max: property.data.maximum
                    })
              : ('DEVICE_DETAIL_CARD.EXCEEDED_BOUNDARIES_WITHOUT_MAXIMUM'
                | translate
                  : {
                      value: property.data.minimum ? property.data.minimum : 0
                    })
          "
        ></p>
        <p
          class="invalid-feedback col-6"
          *ngIf="
            getControl(property.id!)?.errors?.['wrongAmountOfDecimals'] &&
            !(
              getControl(property.id!)?.errors?.['min'] ||
              getControl(property.id!)?.errors?.['max']
            )
          "
        >
          {{ 'DEVICE_DETAIL_CARD.WRONG_AMOUNT_OF_DECIMALS' | translate }}
        </p>
        <p
          class="invalid-feedback col-6"
          *ngIf="
            getControl(property.id!)?.errors?.['noDecimals'] &&
            !(
              getControl(property.id!)?.errors?.['min'] ||
              getControl(property.id!)?.errors?.['max']
            )
          "
        >
          {{ 'DEVICE_DETAIL_CARD.NO_DECIMALS' | translate }}
        </p>
        <p
          class="invalid-feedback col-6"
          *ngIf="getControl(property.id!)?.errors?.['exceededStringLength']"
          [innerHtml]="
            property.data.minLength === property.data.maxLength
              ? ('DEVICE_DETAIL_CARD.FIXED_LENGTH'
                | translate
                  : {
                      length: property.data.maxLength
                    })
              : ('DEVICE_DETAIL_CARD.EXCEEDED_BOUNDARIES'
                | translate
                  : {
                      boundaries:
                        property.data.minLength +
                        ' and ' +
                        property.data.maxLength
                    })
          "
        ></p>
      </li>
    </ng-container>
  </ul>
</form>
