<div
  *ngIf="products$ | async as products; else addDeviceModalComponentLoading"
  class="si-modal-content-icon-wrapper si-modal-content-icon-spacer"
>
  <ng-container *ngIf="form">
    <div class="modal-header">
      <h4
        class="modal-title text-break"
        [attr.data-testid]="'add-device-title'"
        id="sample-modal-title"
      >
        {{ 'BUILDING.ADD_DEVICES_MODAL.TITLE' | translate }} "{{
          location?.name
        }}"
      </h4>
      <button
        type="button"
        class="btn btn-circle btn-sm btn-ghost element-cancel"
        aria-label="Close modal"
        [attr.data-testid]="'add-devices-modal-close-btn'"
        (click)="close()"
      ></button>
    </div>
    <div class="modal-body">
      <si-filtered-search
        [attr.data-testid]="'add-device-search-input'"
        [placeholder]="'BUILDING.SEARCH' | translate"
        [submitText]="'BUILDING.SUBMIT_TEXT' | translate"
        [showIcon]="true"
        [colorVariant]="'base-0'"
        [criteria]="searchCriteria"
        [maxCriteriaOptions]="0"
        (doSearch)="filterProducts($event)"
      ></si-filtered-search>

      <form [formGroup]="form">
        <ul class="list-group overflow-auto max-vh-50 mt-2">
          <li *ngFor="let product of filteredProducts">
            <div
              class="list-group-item d-flex align-items-center justify-content-between border-0 border-bottom"
            >
              <div class="d-flex align-items-center">
                <i
                  class="icon"
                  [ngClass]="{
                    'element-favorites-filled ': product.isFavorite,
                    'element-favorites ': !product.isFavorite
                  }"
                  (click)="addProductToFavorites(product)"
                ></i>
                <img
                  class="my-n4 me-5"
                  alt="Device image"
                  [attr.data-testid]="
                    'device-thumbnail-' + product.thumbnail + '-image'
                  "
                  [src]="
                    (mediaBaseUrl || '') +
                    (product.thumbnail || '/assets/images/device-fallback.svg')
                  "
                  [width]="37"
                />
                <div class="d-flex flex-column">
                  <span
                    class="si-title-2"
                    [attr.data-testid]="'device-' + product.uniqueName + '-name'"
                    >{{ product.title }}</span
                  >
                  <span
                    class="si-body-2 text-break"
                    [attr.data-testid]="'device-' + product.uniqueName + '-id'"
                    >{{ product.model }}</span
                  >
                  <div class="d-flex">
                    <span class="si-caption mt-2"
                      >{{
                        'BUILDING.ADD_DEVICES_MODAL.TOTAL_USED' | translate
                      }}:
                    </span>
                    <span
                      class="si-caption mt-2 px-1"
                      [attr.data-testid]="'device-' + product.uniqueName + '-used'"
                    >
                      {{ product.instances }}</span
                    >
                  </div>
                </div>
              </div>
              <div class="d-flex flex-column h-25 w-25">
                <si-number-input
                  class="form-control min-width-75"
                  [min]="0"
                  [max]="20"
                  [step]="1"
                  [value]="0"
                  [attr.data-testid]="'device-' + product.uniqueName + '-amount'"
                  [formControlName]="product.id"
                ></si-number-input>
                <div *ngIf="form.get(product.id)?.errors" class="mt-2">
                  <span
                    class="text-danger"
                    *ngIf="form.get(product.id)?.hasError('required')"
                    >{{
                      'PLANNING.DEVICE_CATALOG.FIELD_REQUIRED' | translate
                    }}</span
                  >
                  <span
                    class="text-danger"
                    *ngIf="
                      form.get(product.id)?.hasError('exceededProductQuantity')
                    "
                    >{{
                      'PLANNING.DEVICE_CATALOG.QUANTITY_EXCEEDED' | translate
                    }}</span
                  >
                  <span
                    class="text-danger"
                    *ngIf="
                      form
                        .get(product.id)
                        ?.hasError('insufficientProductQuantity') &&
                      form.touched
                    "
                    >{{
                      'PLANNING.DEVICE_CATALOG.INSUFFICIENT_QUANTITY'
                        | translate
                    }}</span
                  >
                </div>
              </div>
            </div>
          </li>
        </ul>
      </form>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-secondary"
        id="btn-close"
        [attr.data-testid]="'add-devices-modal-cancel-btn'"
        (click)="close()"
      >
        {{ 'GLOBALS.BUTTON.CANCEL' | translate }}
      </button>
      <button
        type="button"
        class="btn btn-primary"
        [disabled]="!form.valid || !form.touched"
        [attr.data-testid]="'add-devices-modal-save-btn'"
        (click)="saveDevices(products)"
      >
        {{ 'GLOBALS.BUTTON.SAVE' | translate }}
      </button>
    </div>
  </ng-container>
</div>
<ng-template #addDeviceModalComponentLoading>
  <si-loading-spinner></si-loading-spinner>
</ng-template>
