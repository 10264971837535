import { Action, createReducer, on } from '@ngrx/store';

import { ActiveProjectState } from '../models/active-project-state.model';
import { ActiveProjectActions } from './active-project.actions';
import { LoadedStatus } from '../../../v2/shared/location-store/models/location.model';

export const initialState: ActiveProjectState = {
  latestDevicesCatalog: [],
  loadedStatusDeviceCatalog: LoadedStatus.NOT_LOADED,
  latestFunctionsCatalog: [],
  activeProject: undefined,
  activeBuilding: undefined,
};

const innerActiveProjectReducers = createReducer(
  initialState,
  on(ActiveProjectActions.set, (state, { project }) => ({
    ...state,
    activeProject: project,
  })),
  on(ActiveProjectActions.setBuilding, (state, { building }) => ({
    ...state,
    activeBuilding: building,
  })),
  on(ActiveProjectActions.initLoadDevicesCatalog, (state) => ({
    ...state,
    loadedStatusDeviceCatalog: LoadedStatus.LOADING,
  })),
  on(
    ActiveProjectActions.loadDevicesCatalogSuccess,
    (state, { latestDeviceProducts }) => ({
      ...state,
      latestDevicesCatalog: latestDeviceProducts,
      loadedStatusDeviceCatalog: LoadedStatus.LOADED,
    }),
  ),
  on(
    ActiveProjectActions.loadFunctionsCatalogSuccess,
    (state, { latestFunctionsProducts }) => ({
      ...state,
      latestFunctionsCatalog: latestFunctionsProducts,
    }),
  ),
  on(ActiveProjectActions.clearActiveProjectState, () => ({
    ...initialState,
  })),
);

export function activeProjectReducers(
  state: ActiveProjectState | undefined,
  action: Action,
): ActiveProjectState {
  return innerActiveProjectReducers(state, action);
}
