import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { PlannedDevice } from '../../../../shared/api-client/services/lcas-api-client/models/planned-device.model';
import { DeviceStatus } from '../../../../shared/models/business/device-status.enum';
import {
  DeviceMeasurement,
  DeviceRow,
} from '../../../shared/generic-components/devices-details/models/device-row.model';

@Injectable()
export class DevicesTableDataService {
  constructor(private translateService: TranslateService) {}

  getTableData(devices: PlannedDevice[]): DeviceRow[] {
    return devices.map((device) => ({
      settings: device.settings!,
      id: device.id,
      name: device.name,
      status: this.getStatus(device),
      secondaryStatus: device.secondaryDeviceStatus,
      setupStatus: device.setupStatus,
      type: device.type,
      thumbnail: device.thumbnail,
      config: device.config,
      measurement: this.getMeasurement(device),
      instance: {
        type: device.instance?.model,
        properties: device.instance?.properties,
        version: device.instance?.version,
        model: device.instance?.model,
        uniqueName: device.instance?.uniqueName,
      },
      eTag: device.eTag,
    }));
  }

  private getStatus(device: PlannedDevice): DeviceStatus | undefined {
    if (device.setupStatus === 'COMMISSIONED') {
      return device.deviceStatus;
    }
    if (device.setupStatus === 'READY_FOR_RECOMMISSIONING') {
      return 'connection-lost';
    }

    return undefined;
  }

  private getMeasurement(device: PlannedDevice): DeviceMeasurement | undefined {
    if (
      this.hasMainPropAndMainPropExistsInProperties(device) &&
      device.instance?.properties[device.instance.mainPropertyName].unit
    ) {
      return {
        value: device.value,
        unit: this.translateService.instant(
          device.instance?.properties[device.instance?.mainPropertyName]
            ?.unit as string,
        ),
        multipleOf:
          device.instance?.properties[device.instance?.mainPropertyName]
            ?.multipleOf,
        instance:
          device.instance?.properties[device.instance?.mainPropertyName],
      };
    }

    return device.value ? { value: device.value } : undefined;
  }

  private hasMainPropAndMainPropExistsInProperties(
    device: PlannedDevice,
  ): boolean {
    if (!device.instance?.mainPropertyName) {
      return false;
    }

    return Object.keys(device.instance.properties).includes(
      device.instance.mainPropertyName,
    );
  }
}
