import { createFeature, createReducer, on } from '@ngrx/store';

import { LoadedStatus, LocationsState } from '../models/location.model';
import { LocationActions } from './location.actions';

export const initialState: LocationsState = {
  floorsAndLocationsLoadedStatus: LoadedStatus.NOT_LOADED,
  buildingProductLoadedStatus: LoadedStatus.NOT_LOADED,
  floorsWithLocations: [],
  locationsWithDevices: {},
  products: [],
  isLoading: false,
};

const locationReducers = createReducer(
  initialState,
  on(LocationActions.initLoadFloorsAndLocations, (state) => ({
    ...state,
    floorsAndLocationsLoadedStatus: LoadedStatus.LOADING,
  })),
  on(
    LocationActions.loadFloorsAndLocationsSuccess,
    (state, { floorsWithLocations }) => ({
      ...state,
      floorsAndLocationsLoadedStatus: LoadedStatus.LOADED,
      floorsWithLocations: floorsWithLocations
        .map((floorWithLocations) => ({
          ...floorWithLocations,
          locations: [...(floorWithLocations.locations || [])].sort((l1, l2) =>
            l1.name.localeCompare(l2.name),
          ),
        }))
        .sort((f1, f2) => f1.name.localeCompare(f2.name)),
    }),
  ),
  on(LocationActions.addFloorSuccess, (state, { floor }) => ({
    ...state,
    floorsWithLocations: [
      ...state.floorsWithLocations,
      { ...floor, locations: [] },
    ].sort((f1, f2) => f1.name.localeCompare(f2.name)),
  })),
  on(LocationActions.addLocationSuccess, (state, { location }) => ({
    ...state,
    floorsWithLocations: state.floorsWithLocations.map((floor) =>
      floor.id === location.floor.id
        ? {
            ...floor,
            locations: [...floor.locations, location].sort((l1, l2) =>
              l1.name.localeCompare(l2.name),
            ),
          }
        : floor,
    ),
  })),
  on(LocationActions.patchFloorSuccess, (state, { floor }) => ({
    ...state,
    floorsWithLocations: state.floorsWithLocations
      .map((existingFloor) =>
        existingFloor.id === floor.id
          ? {
              ...existingFloor,
              ...floor,
              locations: existingFloor.locations.map((location) => ({
                ...location,
                floor: {
                  ...location.floor,
                  ...floor,
                },
              })),
            }
          : existingFloor,
      )
      .sort((f1, f2) => f1.name.localeCompare(f2.name)),
  })),
  on(LocationActions.patchLocationSuccess, (state, { location }) => ({
    ...state,
    floorsWithLocations: state.floorsWithLocations.map((floor) =>
      floor.id === location.floor.id
        ? {
            ...floor,
            locations: floor.locations
              .map((existingLocation) =>
                existingLocation.id === location.id
                  ? {
                      ...existingLocation,
                      ...location,
                    }
                  : existingLocation,
              )
              .sort((l1, l2) => l1.name.localeCompare(l2.name)),
          }
        : floor,
    ),
  })),
  on(LocationActions.deleteFloorSuccess, (state, { floorId }) => ({
    ...state,
    floorsWithLocations: state.floorsWithLocations.filter(
      (f) => f.id !== floorId,
    ),
  })),
  on(
    LocationActions.deleteLocationSuccess,
    (state, { floorId, locationId }) => ({
      ...state,
      floorsWithLocations: state.floorsWithLocations.map((floor) =>
        floor.id === floorId
          ? {
              ...floor,
              locations: floor.locations.filter(
                (location) => location.id !== locationId,
              ),
            }
          : floor,
      ),
    }),
  ),
  on(LocationActions.addFloorError, (state) => ({
    ...state,
  })),
  on(LocationActions.addLocationError, (state) => ({
    ...state,
  })),
  on(LocationActions.patchFloorError, (state) => ({
    ...state,
  })),
  on(LocationActions.patchLocationError, (state) => ({
    ...state,
  })),
  on(LocationActions.deleteFloorError, (state) => ({
    ...state,
  })),
  on(LocationActions.deleteLocationError, (state) => ({
    ...state,
  })),
  on(
    LocationActions.loadLocationWithDevicesSuccess,
    (state, { locationId, devices }) => ({
      ...state,
      locationsWithDevices: {
        ...state.locationsWithDevices,
        [locationId]: devices,
      },
    }),
  ),
  on(
    LocationActions.addMultipleDevicesSuccess,
    (state, { locationId, devices }) => ({
      ...state,
      locationsWithDevices: {
        ...state.locationsWithDevices,
        [locationId]: [
          ...(state.locationsWithDevices[locationId] || []),
          ...devices,
        ],
      },
    }),
  ),
  on(LocationActions.deleteDeviceSuccess, (state, { device }) => ({
    ...state,
    locationsWithDevices: device.location?.id
      ? {
          ...state.locationsWithDevices,
          [device.location.id]: state.locationsWithDevices[
            device.location.id
          ].filter((d) => d.id !== device.id),
        }
      : { ...state.locationsWithDevices },
    products: state.products
      .map((product) =>
        product.productCatalogId === device.instance?.uniqueName
          ? {
              ...product,
              quantity: product.quantity - 1,
              isFavorite:
                product.quantity - 1 === 0 ? false : product.isFavorite,
            }
          : product,
      )
      .filter((product) => product.quantity > 0 || product?.isFavorite),
  })),
  on(LocationActions.moveDeviceSuccess, (state, { device, moveLocation }) => ({
    ...state,
    locationsWithDevices: {
      ...state.locationsWithDevices,
      [moveLocation.toLocationId]: [
        ...(state.locationsWithDevices[moveLocation.toLocationId] || []),
        device,
      ],
      [moveLocation.fromLocationId]: state.locationsWithDevices[
        moveLocation.fromLocationId
      ].filter((d) => d.id !== device.id),
    },
  })),
  on(LocationActions.patchDeviceSuccess, (state, { device }) => ({
    ...state,
    locationsWithDevices: device.location?.id
      ? {
          ...state.locationsWithDevices,
          [device.location?.id]: state.locationsWithDevices[
            device.location?.id
          ].map((plannedDevice) =>
            plannedDevice.id === device.id
              ? {
                  ...plannedDevice,
                  ...device,
                }
              : plannedDevice,
          ),
        }
      : { ...state.locationsWithDevices },
  })),
  on(LocationActions.initLoadProducts, (state) => ({
    ...state,
    buildingProductLoadedStatus: LoadedStatus.LOADING,
  })),
  on(LocationActions.loadProductsSuccess, (state, { products }) => ({
    ...state,
    products,
    buildingProductLoadedStatus: LoadedStatus.LOADED,
  })),
  on(LocationActions.deleteDeviceError, (state) => ({
    ...state,
  })),
  on(LocationActions.moveDeviceError, (state) => ({
    ...state,
  })),
  on(LocationActions.patchDeviceError, (state) => ({
    ...state,
  })),
  on(LocationActions.loadProductsError, (state) => ({
    ...state,
  })),
  on(LocationActions.addMultipleDevicesError, (state) => ({
    ...state,
  })),
  on(LocationActions.showLoading, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(LocationActions.stopLoading, (state) => ({
    ...state,
    isLoading: false,
  })),
  on(LocationActions.clearState, (state) => ({
    ...state,
    floorsAndLocationsLoadedStatus: LoadedStatus.NOT_LOADED,
    buildingProductLoadedStatus: LoadedStatus.NOT_LOADED,
    floorsWithLocations: [],
    locationsWithDevices: {},
    products: [],
    isLoading: false,
  })),
);

export const locationFeature = createFeature({
  name: 'floorsAndLocations',
  reducer: locationReducers,
});

export const {
  name: locationFeatureKey,
  reducer: LocationsReducer,
  selectFloorsAndLocationsLoadedStatus,
  selectBuildingProductLoadedStatus,
  selectFloorsWithLocations,
  selectIsLoading,
  selectLocationsWithDevices,
  selectProducts,
} = locationFeature;
