import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { debounce, interval, map, merge, Observable, Subscription } from 'rxjs';

import { EdgeConnectivityInfo } from '../../../../shared/models/edge-connectivity-info.model';
import { EnumSelectValue } from '../controls/enum-select/models/enum-select-value.model';
import { DetailCardProperty } from '../models/detail-card-property.model';
import { checkInput } from '../validators/details-card-input.validator';
import { RoundingDecimalPipe } from '../../../../shared/pipes/rounding-decimal.pipe';
import { PointVerticalValuePipe } from '../pipes/point-vertical-value.pipe';

@Component({
  selector: 'app-details-card-form',
  templateUrl: './details-card-form.component.html',
  styleUrls: ['./details-card-form.component.scss'],
  providers: [PointVerticalValuePipe, RoundingDecimalPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetailsCardFormComponent implements OnChanges, OnDestroy {
  private subscription?: Subscription;

  @Input() properties: DetailCardProperty[] | undefined;

  @Input() edgeConnectivityInfo: EdgeConnectivityInfo | undefined;

  @Output() stopPolling = new EventEmitter();

  @Output() startPolling = new EventEmitter();

  @Output() controlBlur = new EventEmitter();

  @Output() controlValueChange = new EventEmitter<
    | {
        id: string;
        value: any;
      }
    | undefined
  >();

  formGroup?: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private roundingDecimalPipe: RoundingDecimalPipe,
    private pointVerticalValuePipe: PointVerticalValuePipe,
  ) {}

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  ngOnChanges(): void {
    if (!this.properties) {
      throw new Error(`Input value 'properties' is mandatory.`);
    }
    this.formGroup = this.getForm(
      this.properties.filter((p) => !p.data.readOnly),
    );
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.subscription = this.onAnyControlValueChange(this.formGroup).subscribe(
      (valueObject) => this.controlValueChange.emit(valueObject),
    );
  }

  getControl(id: string): AbstractControl | undefined {
    return this.formGroup?.get(id) || undefined;
  }

  getInputType(property: DetailCardProperty): string {
    return (
      [
        { type: 'string', displayType: 'text' },
        { type: 'boolean', displayType: 'checkbox' },
        { type: 'integer', displayType: 'number' },
      ].find((x) => property.data.type === x.type)?.displayType ?? 'number'
    );
  }

  getInputClass(property: DetailCardProperty): Record<string, boolean> {
    const isCheckbox = this.getInputType(property) === 'checkbox';
    return {
      'form-check-input': isCheckbox,
      'form-control': !isCheckbox,
      'text-muted': !!(
        !this.edgeConnectivityInfo?.connected &&
        this.edgeConnectivityInfo?.fetchedConnectivity
      ),
    };
  }

  getOptions(data: Record<string, any>): EnumSelectValue[] {
    return data.enum
      ? this.getEnumSelectValueFromEnum(data)
      : this.getEnumSelectValueFromEnumMap(data);
  }

  private getEnumSelectValueFromEnum(
    data: Record<string, any>,
  ): EnumSelectValue[] {
    return data.enum.map((e: string) => ({
      value: e,
      displayValue: data['btzf:hasEnumMap']['btzf:hasMapMember'][e].ref,
    }));
  }

  private getEnumSelectValueFromEnumMap(
    data: Record<string, any>,
  ): EnumSelectValue[] {
    return Object.keys(data['btzf:hasEnumMap']['btzf:hasMapMember']).map(
      (key) => ({
        value: key,
        displayValue: data['btzf:hasEnumMap']['btzf:hasMapMember'][key]
          .ref as string,
      }),
    );
  }

  private onAnyControlValueChange(
    formGroup: FormGroup,
  ): Observable<{ id: string; value: any }> {
    return merge(
      ...Object.entries(formGroup.controls).map(([id, control]) =>
        control.valueChanges.pipe(
          debounce(() =>
            this.properties?.find((p) => p.id === id)!.data.enum
              ? interval(0)
              : interval(600),
          ),
          map((value) => ({ id, value })),
        ),
      ),
    );
  }

  private getForm(properties: DetailCardProperty[]): FormGroup {
    return this.formBuilder.group(
      properties.reduce(
        (acc, property) => ({
          ...acc,
          [property.id!]: [
            this.getValue(property),
            this.getValidators(property),
          ],
        }),
        {},
      ),
    );
  }

  private getValue(property: DetailCardProperty): string | number | boolean {
    if (property.data.type === 'string') {
      return property.data.default || property.value;
    }
    if (property.data.type === 'boolean') {
      return property.value ?? false;
    }
    return property.value
      ? this.getPropertyValue(property.value, property.data.multipleOf)
      : property.data.minimum || 0;
  }

  private getPropertyValue(
    value: string,
    multipleOf: string | undefined,
  ): string {
    if (multipleOf) {
      return this.roundingDecimalPipe.transform(
        value,
        this.pointVerticalValuePipe.transform(multipleOf),
      );
    }

    return this.pointVerticalValuePipe.transform(value);
  }

  private getValidators(property: DetailCardProperty): ValidatorFn[] {
    return property.data['btzf:hasEnumMap'] || property.data.type === 'boolean'
      ? []
      : [
          Validators.required,
          checkInput(property),
          ...(property.data.type !== 'string'
            ? [
                Validators.max(
                  property.data.maximum ? property.data.maximum : null,
                ),
                Validators.min(
                  property.data.minimum ? property.data.minimum : 0,
                ),
              ]
            : []),
        ];
  }
}
