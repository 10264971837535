import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { ModalRef } from '@simpl/element-ng';

@Component({
  selector: 'app-force-delete-modal',
  templateUrl: './force-delete-modal.component.html',
})
export class ForceDeleteModalComponent implements OnDestroy {
  @Input() modalRef?: ModalRef;

  @Output() forceDelete = new EventEmitter();

  @Output() cancelForceDelete = new EventEmitter();

  ngOnDestroy(): void {
    this.cancelForceDelete.emit();
  }

  onForceDelete(): void {
    this.forceDelete.emit();
    this.modalRef?.hide();
  }

  onCloseModal(): void {
    this.cancelForceDelete.emit();
    this.modalRef?.hide();
  }
}
