export const environment = {
  test: false,
  production: true,
  cookieUrl: '//assets.adobedtm.com/5dfc7d97c6fb/9f2b9af520c3/launch-b25a29efa7a1-staging.min.js',
  apiURL: 'https://dev.lcas.horizondev.cloud/api',
  apiUrlVersion: 'v2',
  userApi: 'https://prodint.um.user.horizonint.cloud/api',
  userApiVersion: 'v2',
  subscriptionApi: 'https://product-int.subscription.horizonint.cloud/api',
  subscriptionApiVersion: 'v2',
  pointApi: 'https://product-int.point.horizonint.cloud/api/v2',
  pointApiVersion: 'v2',
  locationApi: 'https://product-int.locations.horizonint.cloud',
  locationApiVersion: 'v2',
  deviceApi: 'https://product-int-dm.device.horizonint.cloud/api',
  deviceApiVersion: 'v2',
  edgeApi: 'https://productint.hedge.horizonint.cloud/api',
  edgeApiVersion: 'v1',
  aodsApi:'https://product-int.aods.horizonint.cloud/api',
  aodsApiVersion:'v2',
  product: 'lcas-app',
  clientId: 'y5FdQDIWP7KAsB7nkpvuUzopd7RxWuC0',
  audience: 'https://horizonint.cloud',
  issuer: 'https://siemens-qa-bt-015.eu.auth0.com/',
  msIssuer: 'https://btprodeu.piam.eu1.mindsphere.io/oauth/token',
  siemensIdBaseUrl: 'https://login-qa.siemens.com',
  tenant: 'main-tenant-oidc',
  accountManagerAppUrl: 'https://account.apps.horizondev.cloud',
  devEnvAccountManagerAppUrl: 'https://account.apps.horizonint.cloud',
  assetsAppUrl: 'https://assets.apps.horizondev.cloud',
  devEnvAssetsAppUrl: 'https://assets.apps.horizonint.cloud',
  mediaBaseUrl: '',
  subscriptionManagerAppUrl: 'https://siemens-sandbox.subscription-suite.io',
  lcasAppVersion: 'master',
  googleMapsApiKey: 'AIzaSyCUdGQXVSeeCE8-ek8gKyBUckIE3FvOacc',
  scanditApiKey: 'ATeC2Tf5EtL1CJWaekIeAHUqNSa2E0UbPVkpsyUcm30tV6hssXx31mZ/PLi3T57u/m0Oto14YAwvdJDgtXqmK7pnC/07dvB+7Wj7PPAmbekPZGMdYUXLDmlhjWkYajGrs2+P9sFY0u1PeOb5GkuKhVZAryIIXinf0i02WiRGzP/Gau194wlcnkV2vtmIQqgiJ0KnBoUJtsfTAWPo5U5ezI41lONI/AiHK6n7I09UTEM5lTQRxzUp9m/iZdQTtDFpY6FPayCbVzSSc9Fz5jTBs3Tij9aeDVakLnOonFVUO105lIedr01yUjLHkDZsPfW5YkfpPLOb4ZlcbDS4jkCSTCcbvdQhTM0A+EUWULghLa2rSD5iIAsb5f3sdLFwrtufMf58Vj0VWJOvKNAiAANOX+H/DXxJmTWJMQuL/DucujxqhSzH4LkKDYCRl/go4KUKiOaVsL5v7/stI4jb3AZPoMa+R3jRXO9gGoIlXaC9lXX/mY+x2ZyVLtn91FAT2g8/bR13tu7hFMHXrhv14+FPhOhRO5Dbm6yAGUTaL8nltKh2ly5y5TbzpgeYEHEEToXJ+o6yEPBRRujibFXY75UVoTB+1T6To6XZnGKjC17E5887iJMf0fmyu6eUt8/YUM0Y0lb6dStbHjioQWLwDPceDX+s951WFxA67YKKDAGToomjblOpK05k3w11552Wxr6aQlyVi1+kkDEbwlH/cLrBgYtOnH4dVB4x4C2KP+AIc4oUKKh+K+gkH9nXRENIk7I08VnH/luOLR3llSx0fFlf2twudmujQLj9d3HN4HYvr1oHA1+GL4/DLspzuFE7f5PDP0JsR9sVWZSx5/FWpuY6KQbqdDA7yeBEWvYdrkLaqcX9QDnOotrLiSIxcZ0=',
  isChinaDeployment: '',
  pipelineEnvironment: 'dev',
  supportedLanguages: 'en,de,it,fr'
};
