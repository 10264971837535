import { Component, Input } from '@angular/core';
import { ModalRef } from '@simpl/element-ng';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { LocationsState } from '../../../../shared/location-store/models/location.model';
import { LocationActions } from '../../../../shared/location-store/store/location.actions';

@Component({
  selector: 'app-create-floor-modal',
  template: `
    <app-create-floor-or-location-modal
      [title]="title"
      [defaultName]="name"
      [modalRef]="modalRef"
      (save)="save($event)"
    ></app-create-floor-or-location-modal>
  `,
})
export class CreateFloorModalComponent {
  @Input() modalRef?: ModalRef;

  readonly title = 'BUILDING.ADD_FLOOR';

  readonly name = this.translateService.instant('BUILDING.ADD_FLOOR_NAME');

  constructor(
    private translateService: TranslateService,
    private store: Store<{
      floorsAndLocations: LocationsState;
    }>,
  ) {}

  save(floorName: string) {
    this.store.dispatch(LocationActions.initAddFloor({ floorName }));
    this.modalRef?.hide();
  }
}
