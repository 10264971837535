import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  DeleteConfirmationDialogResult,
  SiActionDialogService,
  TreeItem,
} from '@simpl/element-ng';
import { first, map, Observable, of, tap } from 'rxjs';

import { LcasApiClientService } from '../../../shared/api-client/services/lcas-api-client/lcas-api-client.service';
import { PlannedDevice } from '../../../shared/api-client/services/lcas-api-client/models/planned-device.model';
import { ParentEntity } from '../../../shared/models/business/parent-entity.enum';
import { LocationsState } from '../location-store/models/location.model';
import { LocationActions } from '../location-store/store/location.actions';
import { TreeItemWithId } from '../models/tree-item-with-id.model';

@Injectable({ providedIn: 'root' })
export class DeleteTreeItemService {
  constructor(
    private siActionDialogService: SiActionDialogService,
    private lcasApiClientService: LcasApiClientService,
    private store: Store<{
      floorsAndLocations: LocationsState;
    }>,
  ) {}

  private entityToDelete: ParentEntity | undefined = undefined;

  private floorIdToDelete: string | undefined = undefined;

  private locationIdToDelete: string | undefined = undefined;

  handleDelete(treeItem: TreeItem): Observable<PlannedDevice[]> {
    if (treeItem.level === 0) {
      this.entityToDelete = 'floor';
      this.floorIdToDelete = (treeItem as TreeItemWithId).id;
    } else {
      this.entityToDelete = 'location';
      this.floorIdToDelete = (treeItem.parent as TreeItemWithId).id;
      this.locationIdToDelete = (treeItem as TreeItemWithId).id;
    }
    return this.lcasApiClientService
      .getDevices({
        floorId: this.entityToDelete === 'floor' ? this.floorIdToDelete : '',
        locationId: this.locationIdToDelete,
      })
      .pipe(
        map((devices) =>
          devices.filter(
            (device) =>
              device.setupStatus === 'COMMISSIONED' ||
              device.type === 'SysXController',
          ),
        ),
        tap((devices) => {
          if (!devices.length) {
            this.openDeleteConfirmationModal();
          }
        }),
      );
  }

  private openDeleteConfirmationModal(): Observable<undefined> {
    this.siActionDialogService
      .showDeleteConfirmationDialog(
        'COMMISSIONING.DEVICE_TABLE.DELETE_DEVICE.DESCRIPTION',
        this.entityToDelete === 'floor'
          ? 'BUILDING.DELETE_FLOOR'
          : 'BUILDING.DELETE_ROOM',
        'GLOBALS.BUTTON.DELETE',
        'GLOBALS.BUTTON.CANCEL',
      )
      .pipe(
        first(),
        tap((response) => {
          if (response === DeleteConfirmationDialogResult.Delete) {
            this.deleteLocationOrFloor();
          }
        }),
      )
      .subscribe();
    return of(undefined);
  }

  private deleteLocationOrFloor(): void {
    if (this.entityToDelete === 'floor') {
      this.store.dispatch(
        LocationActions.initDeleteFloor({
          floorId: this.floorIdToDelete!,
        }),
      );
    } else {
      this.store.dispatch(
        LocationActions.initDeleteLocation({
          floorId: this.floorIdToDelete!,
          locationId: this.locationIdToDelete!,
        }),
      );
    }
  }
}
