import { ColumnMode } from '@siemens/ngx-datatable';
import { SI_DATATABLE_CONFIG } from '@simpl/element-ng';
import { DevicesTableConfig } from '../models/devices-table-config.model';

export const datatableConfig = {
  columnMode: ColumnMode.force,
  cssClasses: SI_DATATABLE_CONFIG.cssClasses,
  tableClass: 'table-element elevation-1',
  headerClass: 'text-body py-5',
  headerHeight: 50,
  rowHeightSmall: 64,
  rowHeightMedium: 90,
  rowHeightLarge: 112,
  actionsColumnWidth: 64,
  arrowColumnWidth: 50,
  trackByProp: 'id',
  rowIdentity: (row: { id: string }) => row.id,
};

export const devicesTableConfigPlanningBuilding: DevicesTableConfig = {
  showThreeDotsColumn: true,
  showReadonlyDeviceName: false,
  showMoveActionButton: true,
  showReplaceDeviceActionButton: false,
  showAddDevicesButton: true,
  showAssignButton: false,
  showStatusColumn: false,
  showValueColumn: false,
  showStatusAndConnectColumn: false,
  showDetailsButtonOnSmallerScreens: true,
  showUpdateThingInstanceActionButton: false,
  tableConfig: {
    ...datatableConfig,
    cellClass: 'visible-overflow',
    rowHeightSmall: 76,
    columnMinWidth: 80,
    deviceNameColumnMinWidth: 100,
    secondaryActionsColumnMinWidthOnLargerScreens: 145,
    secondaryActionsColumnMinWidthOnSmallerScreens: 45,
    limitMediaScreenBreak: 768,
  },
};

export const devicesTableConfigScanAndAssign: DevicesTableConfig = {
  showThreeDotsColumn: true,
  showStatusColumnOnSmallerScreens: false,
  showReadonlyDeviceName: true,
  showMoveActionButton: false,
  showReplaceDeviceActionButton: true,
  showAddDevicesButton: false,
  showValueColumn: true,
  showStatusColumn: true,
  showAssignButton: true,
  showStatusAndConnectColumn: true,
  showDetailsButtonOnSmallerScreens: false,
  showUpdateThingInstanceActionButton: true,
  tableConfig: {
    ...datatableConfig,
    rowHeightSmall: 'auto',
    columnMinWidth: 80,
    deviceNameColumnMinWidth: 90,
    secondaryActionsColumnMinWidthOnLargerScreens: 145,
    secondaryActionsColumnMinWidthOnSmallerScreens: 45,
    statusAndConnectColumnWidth: 60,
    limitMediaScreenBreak: 768,
  },
};

export const devicesTableConfigOperationBuilding: DevicesTableConfig = {
  showThreeDotsColumn: false,
  showStatusColumnOnSmallerScreens: true,
  showReadonlyDeviceName: true,
  showMoveActionButton: false,
  showReplaceDeviceActionButton: false,
  showAddDevicesButton: false,
  showValueColumn: true,
  showStatusColumn: true,
  showAssignButton: false,
  showStatusAndConnectColumn: false,
  showDetailsButtonOnSmallerScreens: true,
  showUpdateThingInstanceActionButton: false,
  tableConfig: {
    ...datatableConfig,
    rowHeightSmall: 'auto',
    columnMinWidth: 80,
    deviceNameColumnMinWidth: 90,
    secondaryActionsColumnMinWidthOnLargerScreens: 145,
    secondaryActionsColumnMinWidthOnSmallerScreens: 45,
    statusAndConnectColumnWidth: 60,
    limitMediaScreenBreak: 768,
  },
};
