import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { ModalRef } from '@simpl/element-ng';

import { Location } from '../../../../../shared/api-client/services/lcas-api-client/models/location.model';
import { LocationsState } from '../../../../shared/location-store/models/location.model';
import { LocationActions } from '../../../../shared/location-store/store/location.actions';
import { LocationType } from '../../../../shared/models/location-type.enum';

@Component({
  selector: 'app-edit-location-modal',
  template: `
    <app-edit-location-base-modal
      [type]="locationType.Room"
      [location]="location"
      [modalRef]="modalRef"
      (save)="save($event)"
    ></app-edit-location-base-modal>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditLocationModalComponent {
  @Input() modalRef?: ModalRef;

  @Input() location?: Location;

  locationType = LocationType;

  constructor(
    private store: Store<{
      floorsAndLocations: LocationsState;
    }>,
  ) {}

  save(name: string) {
    if (this.location) {
      this.store.dispatch(
        LocationActions.initPatchLocation({
          location: { ...this.location, name },
        }),
      );
    }
    this.modalRef?.hide();
  }
}
