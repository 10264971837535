import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  Criterion,
  ModalRef,
  SearchCriteria,
  SiSidePanelService,
} from '@simpl/element-ng';
import { intersection } from 'lodash';
import { first, map, Observable } from 'rxjs';

import { Device } from '../../../../shared/api-client/services/lcas-api-client/models/device.model';
import { Location } from '../../../../shared/api-client/services/lcas-api-client/models/location.model';
import { DeviceDetailsRow } from '../../generic-components/devices-details/models/device-details-row.model';
import { LocationsState } from '../../location-store/models/location.model';
import { LocationActions } from '../../location-store/store/location.actions';
import { DeviceActionService } from '../../services/device-action.service';
import { FloorLocation } from './models/floor-location.model';
import { MoveLocation } from './models/move-location.model';

@Component({
  selector: 'app-move-device-modal',
  templateUrl: './move-device-modal.component.html',
})
export class MoveDeviceModalComponent {
  @Input() modalRef?: ModalRef;

  @Input() device?: DeviceDetailsRow;

  @Input() location?: Location;

  locations$: Observable<FloorLocation[]> = this.getLocations().pipe(
    first(),
    map((locations) => {
      this.locations = locations ?? [];
      this.filteredLocations = locations;
      this.setSearchCriteria(locations);

      return locations;
    }),
  );

  locations: FloorLocation[] = [];

  filteredLocations: FloorLocation[] = [];

  selectedLocation: FloorLocation | undefined = undefined;

  searchCriteria: Criterion[] = [];

  private searchCriteriaDict = {
    searchCriteriaFloor: 'searchCriteriaFloor',
    searchCriteriaRoom: 'searchCriteriaRoom',
  };

  constructor(
    private store: Store<{ floorsAndLocations: LocationsState }>,
    private deviceActionService: DeviceActionService,
    private changeDetectorRef: ChangeDetectorRef,
    private siSidePanelService: SiSidePanelService,
  ) {}

  close(): void {
    this.modalRef?.hide();
  }

  moveDevice(): void {
    if (this.selectedLocation && this.location && this.device) {
      const locationDevice: MoveLocation = {
        toFloorId: this.selectedLocation.floor.id,
        toLocationId: this.selectedLocation.location.id,
        fromLocationId: this.location.id,
        device: {
          ...(this.device as Device),
          location: this.selectedLocation.location,
        },
      };
      this.store.dispatch(
        LocationActions.initMoveDevice({
          moveLocation: locationDevice,
        }),
      );
    }
    this.siSidePanelService.close();
    this.close();
  }

  filterLocations(searchCriteria: SearchCriteria): void {
    const criteriaDict = {
      searchCriteriaFloor: this.getCriteriaValueByName(
        searchCriteria,
        this.searchCriteriaDict.searchCriteriaFloor,
      ),
      searchCriteriaRoom: this.getCriteriaValueByName(
        searchCriteria,
        this.searchCriteriaDict.searchCriteriaRoom,
      ),
    };

    this.filteredLocations = intersection(
      this.locations.filter(
        (x) =>
          !criteriaDict.searchCriteriaFloor ||
          this.valueContains(x.floor.name, criteriaDict.searchCriteriaFloor),
      ),
      this.locations.filter(
        (x) =>
          !criteriaDict.searchCriteriaRoom ||
          this.valueContains(x.location.name, criteriaDict.searchCriteriaRoom),
      ),
      this.locations.filter(
        (x) =>
          !searchCriteria.value ||
          this.valueContains(x.floor.name, searchCriteria.value) ||
          this.valueContains(x.location.name, searchCriteria.value),
      ),
    );

    this.changeDetectorRef.detectChanges();
  }

  private valueContains(value: string, contains: string): boolean {
    return value.toLowerCase().includes(contains.toLowerCase());
  }

  private getCriteriaValueByName(
    searchCriteria: SearchCriteria,
    name: string,
  ): string | undefined {
    if (searchCriteria.criteria.some((x) => x.name === name)) {
      return searchCriteria.criteria.find((x) => x.name === name)
        ?.value as string;
    }

    return undefined;
  }

  private getLocations(): Observable<FloorLocation[]> {
    return this.deviceActionService.getLocations();
  }

  private setSearchCriteria(locations: FloorLocation[]): void {
    this.searchCriteria.push(
      {
        name: this.searchCriteriaDict.searchCriteriaFloor,
        label: 'BUILDING.MOVE_DEVICE_MODAL.SEARCH_CRITERIA.FLOOR',
        options: [...new Set(locations.map((item) => item.floor.name))],
      } as Criterion,
      {
        name: this.searchCriteriaDict.searchCriteriaRoom,
        label: 'BUILDING.MOVE_DEVICE_MODAL.SEARCH_CRITERIA.ROOM',
        options: [...new Set(locations.map((item) => item.location.name))],
      } as Criterion,
    );
  }
}
